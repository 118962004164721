// custom typefaces
require("@fontsource-variable/montserrat");
require("@fontsource/merriweather");
// Import Bootstrap
require('./src/bootstrap.css');
// normalize CSS across browsers
require("./src/normalize.css");
// custom CSS styles
require("./src/style.css");

// Highlighting for code blocks
require("prismjs/themes/prism.css");

exports.shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
  }) => {
    const currentPosition = getSavedScrollPosition(location);
    setTimeout(() => { window.scrollTo(...(currentPosition || [0, 0])); }, 100);
  
    return false;
  };
  